import { MESSAGE_DELAY, modules } from '@/constants';
import { defineStore } from 'pinia';
import Api, { Method } from '~utils/Api';
import { ConstantParams } from './types';

const api = new Api();

export const useMainStore = defineStore('main', {
  state: () => ({
    constantParams: {
      id_login: '',
      TK: '',
      IMEI: '',
      Name_app: '',
      Name_module: modules.payment,
    } as ConstantParams,
    modal: false,
    modalComponent: {
      name: '',
      params: {},
    },
    message: false,
    messageContent: '',
  }),
  getters: {
    getConstantParams: (state) => state.constantParams,
  },
  actions: {
    openModal(componentName: string, params: any) {
      this.modal = true;
      this.modalComponent.name = componentName;
      this.modalComponent.params = params;
    },
    closeModal() {
      this.modal = false;
      this.modalComponent = {
        name: '',
        params: {},
      };
    },
    showMessage(content: string) {
      this.message = true;
      this.messageContent = content;
      setTimeout(() => {
        this.message = false;
        this.messageContent = '';
      }, MESSAGE_DELAY);
    },
    async dispatchEvent(eventName: string, params: any = null) {
      const [Name_event, Name_module] = eventName.split('.');
      const method = Name_event.split(':')[0] as Method;
      const data = {
        ...params,
        ...this.constantParams,
        Name_event,
        Name_module,
      };
      // const result = await api.all(method, data);
    },
    clearConstantParams() {
      this.constantParams = {
        id_login: '',
        TK: '',
        IMEI: '',
        Name_app: '',
        Name_module: modules.payment,
      };
    },
    setConstantParams(params: ConstantParams) {
      console.log(params);
      this.constantParams = params;
    },
    // async fetchData(params: RequestParams): Promise<any> {
    //   const { Name_event } = params;
    //   const method = Name_event.startsWith('GET') ? 'GET' : 'POST';
    //   const data = { ...this.constantParams, ...params };
    //   const result = await api.all(method, data);
    //   if (result.errorType) {
    //     return result.errorText;
    //   }
    //   if (result.body) {
    //     return result.body;
    //   }
    //   return false;
    // },
  },
});
