
import { defineComponent } from 'vue';
import QRPayment from '@/components/qr/QRPayment.vue';

export default defineComponent({
  name: 'QRView',
  components: {
    QRPayment,
  },
  setup() {
    return {};
  },
});
