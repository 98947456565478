import { RouteRecordRaw } from 'vue-router';
import LoginView from '~views/LoginView.vue';
import PageNotFound from '~views/PageNotFound.vue';
import QRView from '~views/QRView.vue';
import { getCookie } from '~utils/cookies';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    beforeEnter(to, from, next) {
      const store = useAuthStore();
      const toCompare = store.isAuth || (!!getCookie('id_login') && !!getCookie('TK'));
      if (toCompare) next({ path: '/qr' });
      else next();
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/login',
  },
  {
    path: '/qr',
    name: 'QR',
    component: QRView,
    beforeEnter(to, from, next) {
      const store = useAuthStore();
      const toCompare = store.isAuth || (!!getCookie('id_login') && !!getCookie('TK'));
      if (!toCompare) next({ path: '/login' });
      else next();
    },
  },
];

export default routes;
