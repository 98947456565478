// eslint-disable-next-line import/no-extraneous-dependencies
import * as io from 'socket.io-client';

const options = {
  path: '/payments/',
};

class SocketioService {
  socket;

  setupSocketConnection() {
    this.socket = io('https://host1.medsafe.tech:50505', options);
    return this.socket;
  }
}

export default new SocketioService();
