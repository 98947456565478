import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
import { ConstantParams } from '../types';

const api = new Api();
interface ActiveQRParams {
  Name_event: string;
  id_branch: number;
  dontlogcode: number;
  actuality?: number;
  id_pay_set?: number;
}
export const usePaymentStore = defineStore('payment', {
  state: () => ({
    // constantParams: useMainStore().constantParams,
    branches: [] as any,
    activePayments: [] as any,
    selectedBranch: null as any,
    processing: false,
  }),
  getters: {
    constantParams: () => useMainStore().constantParams,
  },
  actions: {
    async getBranchList() {
      const params = {
        Name_event: 'GET:branches_list',
        // ...useMainStore().constantParams,
        ...this.constantParams,
      };
      const result = await api.get(params);
      if (!result.errorType) {
        const body = <any>result.body;
        this.branches = body;
      }
    },

    async postQROnBnrach(context?: { actuality: number, id_pay_set: number }) {
      if (this.processing) return;
      this.processing = true;
      try {
        const params: ActiveQRParams = {
          Name_event: 'POST:activate_qr_on_branch',
          id_branch: this.selectedBranch.ID,
          dontlogcode: 1,
          ...this.constantParams,
        };
        if (context?.actuality && context?.id_pay_set) {
          params.actuality = context.actuality;
          params.id_pay_set = context.id_pay_set;
        }
        const result = await api.post(params as any);
        if (!result.errorType) {
          const body = <any>result.body;
          this.activePayments = body;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.processing = false;
      }
    },
  },
});
