
import {
  defineComponent, ref, reactive, onMounted, onUnmounted, watch,
} from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { usePaymentStore } from '@/store/payment/paymentModule';
import { storeToRefs } from 'pinia';
import SocketioService from '~utils/socket.io';

export default defineComponent({
  name: 'QRPayment',
  setup() {
    const paymentStore = usePaymentStore();
    const $q = useQuasar();
    const { branches, activePayments, selectedBranch } = storeToRefs(paymentStore);
    let socket: any;
    watch(
      () => activePayments.value,
      () => {
        if (activePayments.value && activePayments.value.length) {
          const payment = activePayments.value[0];
          if (payment.pay_status_text.includes('оплачено') && payment.actuality === 1) {
            $q.notify({
              type: 'positive',
              timeout: 3000,
              position: 'center',
              message: 'Оплата прошла успешно!',
            });
            paymentStore.postQROnBnrach({
              actuality: -1,
              id_pay_set: payment.id_pay_set,
            });
          }
          if (payment.pay_status_text === 'ошибка') {
            $q.notify({
              type: 'negative',
              timeout: 3000,
              message: 'Произошла ошибка!',
            });
          }
        }
      },
    );
    const disconnect = () => {
      socket.emit('exit', 'Bye bye!');
    };

    watch(() => selectedBranch.value, () => {
      if (selectedBranch.value) {
        if (socket) socket.emit('confirm_exit');
        socket = SocketioService.setupSocketConnection();

        socket.on('start', (data: any) => {
          if (data === 'Tinkoff QR notifier listening...') {
            socket.emit('greet', selectedBranch.value.ID);
          }
        });
        socket.on('update', (data: any) => {
          console.log(data);
          paymentStore.postQROnBnrach();
        });
        socket.on('connection', () => {
          console.log('Welcome back!');
        });
        socket.on('disconnect', (data: any) => {
          console.log(data);
        });
        socket.on('confirm', (data: any) => {
          console.log(data);
        });
        socket.on('confirm_exit', (data: any) => {
          console.log(data);
          if (data === 'Socket has been destroyed') {
            socket.close();
          }
        });
      }
    });
    onUnmounted(() => {
      disconnect();
    });

    return {
      branches,
      activePayments,
    };
  },
});
