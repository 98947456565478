import { NAME_APP, modules } from '@/constants';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import router from '@/router/index';
import Api from '~utils/Api';
import { setCookie, deleteCookie, getCookie } from '~utils/cookies';
import { ConstantParams } from '../types';
import { useMainStore } from '../index';
import { User, UserData } from './types';

const api = new Api();

const SECONDS_IN_DAY = 60 * 60 * 24;

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: false,
    IMEI: '',
    user: {} as User,
  }),

  getters: {
    getIsAuth: (state) => state.isAuth,
    currentUser: (state) => state.user,
    currentUserId: (state) => state.user.id_login,
    userName: (state) => state.user.vrach_fio || '',
  },

  actions: {
    async getImei() {
      this.IMEI = await api.IMEI;
    },

    async signIn(login: string, password: string): Promise<void> {
      if (!this.IMEI) {
        await this.getImei();
      }
      const params = {
        login,
        password,
        IMEI: this.IMEI,
        Name_app: NAME_APP,
      };
      try {
        const result = await api.login(params);
        if (result) {
          this.user = <User><unknown>result;
          console.log(this.user);
          const constantParams: ConstantParams = {
            IMEI: this.IMEI,
            Name_app: NAME_APP,
            TK: this.user.TK,
            id_login: this.user.id_login,
            Name_module: modules.payment,
          };
          setCookie('id_login', this.user.id_login, { secure: true, expires: SECONDS_IN_DAY });
          setCookie('TK', this.user.TK, { secure: true, expires: SECONDS_IN_DAY });
          useMainStore().setConstantParams(constantParams);
          this.isAuth = true;
          router.push({ name: 'QR' });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async autenticate(userData: UserData): Promise<void> {
      if (!this.IMEI) {
        await this.getImei();
      }
      const params = {
        ...userData,
        IMEI: this.IMEI,
        Name_event: 'GET:user_data',
        Name_app: NAME_APP,
        Name_module: modules.webmanagers,
      };
      const result = await api.get(params);
      if (!result.errorType && result.body) {
        [this.user] = result.body;
        const constantParams: ConstantParams = {
          IMEI: this.IMEI,
          Name_app: NAME_APP,
          TK: userData.TK,
          id_login: userData.id_login,
          Name_module: modules.payment,
        };
        useMainStore().setConstantParams(constantParams);
        this.isAuth = true;
      } else this.logout();
    },

    async logout(): Promise<void> {
      this.isAuth = false;
      this.user = {} as User;
      useMainStore().clearConstantParams();
      deleteCookie('id_login');
      deleteCookie('TK');
      router.push({ name: 'Login' });
    },
  },
});
